import React, { Component } from 'react';
import {Switch} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import {Suspense} from 'react';

import ProtectedRoute from './hoc/RouteWrapper/ProtectedRoute';

import ErrorBoundary from './hoc/ErrorBoundary/ErrorBoundary';
import Spinner from './components/UI/Spinner/Spinner';


// Lazy Load...
const ViewCourse = React.lazy(() => import('./pages/private/courses/Course/Course'));
const CourseExitIframe = React.lazy(() => import('./pages/private/courses/Course/CourseExitIframe'));



class PrivateCourseIframeRoutes extends Component {


    render() {

       // console.log("PRIVATE Course Iframe ROUTES CALLED", this.props.user_id, this.props.role_id);

        return (
            <React.Fragment>

                <ErrorBoundary>
                    <Suspense fallback={<Spinner/>} maxDuration={1000}>

                        <Switch>

                            <ProtectedRoute key="/home/courses/course/iframe/play" path="/home/courses/course/iframe/play/:course_code"  component={ViewCourse} />
                            <ProtectedRoute key="/home/courses/course/iframe/exit" path="/home/courses/course/iframe/exit/:course_code"  component={CourseExitIframe} />

                        </Switch>

                    </Suspense>
                </ErrorBoundary>

            </React.Fragment>
        );
    }
}



export default withRouter(PrivateCourseIframeRoutes);
