import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import RetinaImage from '../RetinaImage/RetinaImage';
import BackToTop from '../BackToTop/BackToTop';

import classes from './Footer.module.scss';


const Footer = (props) => {

    const year = (new Date()).getFullYear();

    return (

        <footer className={classes.footer}>
            <BackToTop/>
            <Container>
                <Row className={classes.rowSpacing + " pl-2 pl-lg-0"}>
                    <Col lg={3} className={"pl-0"}>
                        {/*<a href="#" rel="noreferrer">*/}
                            <RetinaImage className={"img-fluid"}
                                // onClick={this.goHome}
                                         width={286}
                                         height={55}
                                         src={[
                                             require("../../assets/images/logos/logo-vidalence-logo.png").default,
                                             require("../../assets/images/logos/logo-vidalence-logo@2x.png").default,
                                             require("../../assets/images/logos/logo-vidalence-logo@3x.png").default]}
                                         alt="Vidalence Academy" />
                        {/*</a>*/}
                    </Col>
                    <Col className={"pt-4 pt-lg-2 pt-sm-4 ml-lg-3"}>
                        <Row className={"justify-content-end"}>
                            <ul className={"list-unstyled d-md-inline-flex para-4 mb-2 "+classes.link}>
                                <li><a href="#" target="_blank" rel="noreferrer">Privacy Policy</a></li>
                                <li className="ml-md-4 mt-2 mt-md-0"><a href="#" target="_blank" rel="noreferrer">Terms of Use</a></li>
                            </ul>
                        </Row>

                        <Row className={"justify-content-end"}>
                            <p className={"mt-4 mt-lg-0 p-extra-small white"}>
                                © {year} Vidalence. All Rights Reserved.
                            </p>
                        </Row>
                    </Col>

                </Row>

            </Container>
        </footer>
    );
}


export default Footer;