import React, {Component, useCallback} from 'react';
import {withRouter} from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RetinaImage from '../RetinaImage/RetinaImage';
// import DatedBanner from "../Banners/DatedBanner/DatedBanner";


import classes from './PublicHeader.module.scss';

const PublicHeader = (props) => {


    const goHome = useCallback( () => {
        props.history.push("/");
    });


    return (
        <>

            {/*<DatedBanner code={"privacy-policy-update-dec-2022"} startDate={"2022/12/16"} endDate={"2022/12/31"}/>*/}

            <header className={classes.header}>
                <Container>
                    <Row>
                        <Col sm={6}>
                            <RetinaImage className={classes.logoImg+" img-fluid"}
                                         onClick={goHome}
                                         width={286}
                                         height={55}
                                         src={[
                                             require("../../assets/images/logos/logo-vidalence-logo.png").default,
                                             require("../../assets/images/logos/logo-vidalence-logo@2x.png").default,
                                             require("../../assets/images/logos/logo-vidalence-logo@3x.png").default]}
                                         alt="Company Logo" />
                        </Col>
                        <Col sm={6}>
                            {/*<RetinaImage className={classes.logoImg+" img-fluid"}*/}
                            {/*onClick={this.goHome}*/}
                            {/*width={560}*/}
                            {/*height={31}*/}
                            {/*src={[*/}
                            {/*    require("../../assets/images/logos/logo-pillar.png").default,*/}
                            {/*    require("../../assets/images/logos/logo-pillar@2x.png").default,*/}
                            {/*    require("../../assets/images/logos/logo-pillar@3x.png").default]}*/}
                            {/*alt="Vidalence" />*/}
                        </Col>
                    </Row>
                </Container>

            </header>
        </>
    );

}

export default withRouter(PublicHeader);