import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from "../../store/actions";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import PublicHeader from '../../components/Header/PublicHeader';
import Footer from '../../components/Footer/Footer';
import Main from '../../components/Main/Main'


class ErrorBoundary extends React.Component {

    componentDidCatch(error, info) {

        this.props.onSetHasSystemError(true);

        console.log("ErrorBoundary.componentDidCatch", error, info)
    }

    goHome = () => {

        this.props.onSetHasSystemError(false);

        this.props.history.push("/home");
    };


    reloadScreen = () => {

        this.props.onSetHasSystemError(false);

        window.location.reload();
    };

    render() {
        if (this.props.hasSystemError) {
            return (

                <React.Fragment>

                    <PublicHeader/>

                    <Main>
                        <Container className={"mt-5 mb-5"}>

                            <div className="container">

                                <h2 className="section-header-text text-center">Warning</h2>

                                <p className="paragraphp5 text-center mt-4">
                                    An error has been detected.
                                    <br/><br/>
                                    Confirm you have internet access.
                                    <br/><br/>
                                    If the problem persists, reload the page.
                                </p>

                                <Row>
                                    <Col className={"text-center"}>
                                        <button className="btn btn-primary mt-3 mr-md-3" onClick={this.reloadScreen}>Reload Page</button>
                                        <button className="btn btn-primary mt-3" onClick={this.goHome}>Go to the Home Screen</button>
                                    </Col>
                                </Row>




                            </div>
                        </Container>
                    </Main>

                    <Footer/>

                </React.Fragment>
            );
        }

        return this.props.children;
    }
}



const mapStateToProps = (state) => {
    return {
        hasSystemError: state.misc.hasSystemError
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetHasSystemError: (hasSystemError) => {
            dispatch( actions.setHasSystemError(hasSystemError) );
        }
    }

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ErrorBoundary));