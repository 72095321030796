import React, { Component } from 'react';
import {Switch} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import {Route} from 'react-router-dom';
import {connect} from 'react-redux';

import firebase from 'firebase/app';
import 'firebase/auth';

import * as actions from "./store/actions";
import axios from "./utils/config-axios";
import {buildLogMessage} from './utils/error-helper';

import ProtectedRoute from './hoc/RouteWrapper/ProtectedRoute';
import PrivateRoutes from './PrivateRoutes';
import PrivateCourseIframeRoutes from './PrivateCourseIframeRoutes';
import PublicRoutes from './PublicRoutes';
import WistiaManager from './components/WistiaWrapper/WistiaManager';
import Spinner from './components/UI/Spinner/Spinner';
import CookieConsent from "./components/CookieConsent/CookieConsent";

import './App.scss';



/**
 * CHECKED-2021
 *  TBD - Add version change against db
 */
class App extends Component {


    state = {
        initialized: false
    };



    componentDidMount(){

        //
        // Add listener to detect role_id change.
        //
        firebase.auth().onIdTokenChanged((user) => {
            if (user) {
                user.getIdTokenResult().then((idTokenResult) => {

                    //
                    // Only set new role_id if:
                    // 1) A role_id exists.
                    // 2) Its different then the existing role_id.
                    //
                    if( this.props.role_id ){
                        if( this.props.role_id !== idTokenResult.claims.role_id){
                            this.props.setSecurityContextRole(idTokenResult.claims.role_id);
                        }
                    }
                })
            }
        });


        //
        // Add listener to detect login/logout.
        //
        firebase.auth().onAuthStateChanged( (user) => {

            // console.log("firebase.auth().onAuthStateChanged()", user);

            if (user) {
                //
                // User is signed in.
                //
                //console.log("USER LOGGED IN 1", user);
                // console.log("USER LOGGED IN 2", user.uid);

                user.getIdTokenResult().then((idTokenResult) => {

                    // console.log(idTokenResult);

                    return parseInt(parseInt(user.uid));
                }).then(gcp_user_id => {

                    //
                    // Get user profile data for local storage...
                    //
                    return axios({
                        url: '/api/private/user/profile/get-and-validate-user-profile',
                        method: 'POST',
                        data: {user_id: gcp_user_id}
                    });
                }).then(result => {

                    const dbuser = result.data.user;

                    // console.log("dbuser",result.data);

                    this.props.setSecurityContext( {
                        user_id:dbuser.id,
                        username: dbuser.username,
                        role_id: dbuser.role_id,
                        firstname: dbuser.firstname,
                        lastname: dbuser.lastname,
                        sales_team_role_type_array: dbuser.sales_team_role_type_array
                    });

                    this.setState({initialized:true});

                    //
                    // If this is an Oauth login and the user is sitting on the
                    // Oauth screen, send them home.
                    //
                    if(this.props.location.pathname==="/oauth/callback"){
                        this.props.history.push("/home");
                    }

                }).catch( (error) => {

                    // Set to -1 as the status. System Error.
                    this.props.setSecurityContextLoginMessage("An error has occurred.",-1);

                    console.log("Authentication error detected.", buildLogMessage(error) );

                    this.signUserOutOnError();
                });

            } else {
                //
                // No user is signed in.
                //
                // console.log("NO USER LOGGED IN");
                this.props.clearSecurityContext();

                this.setState({initialized:true});
            }
        });
    }



    signUserOutOnError = () => {

        this.props.clearSecurityContext();
        this.setState({initialized:true});

        if(firebase.auth().currentUser){
            // Sign out user...
            firebase.auth().signOut().then(() => {
                // Sign-out successful.
            }).catch((error) => {
                // An error happened.
            });
        }
    };






    render() {

        if(!this.state.initialized){
            return <Spinner/>;
        }

        // const role_id = this.props.role_id;

        // console.log("APP-role_id", role_id)


        //
        // WARNING:
        //
        // Registration must go before the private routes.
        // This is due to the registration auto login process.
        //

        return (
            <React.Fragment>

                <Switch>


                    <Route path="/registration">
                        <PublicRoutes />
                    </Route>

                    <ProtectedRoute key="/home/courses/course/iframe" path="/home/courses/course/iframe" component={PrivateCourseIframeRoutes} />

                    <ProtectedRoute key="/home" path="/home" component={PrivateRoutes} />

                    <Route path="/">
                        <PublicRoutes />
                    </Route>

                </Switch>

                <CookieConsent/>

                <WistiaManager supportBackdrop={false}/>

            </React.Fragment>
        );
    }
}




const mapStateToProps = (state) => {
    return {
        user_id: state.security.user_id,
        role_id: state.security.role_id,
        username: state.security.username,
        firstname: state.security.firstname,
        lastname: state.security.lastname
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSecurityContext: (user) => {
            dispatch( actions.setSecurityContext(user) );
        },
        setSecurityContextRole: (role_id) => {
            dispatch( actions.setSecurityContextRole(role_id) );
        },
        clearSecurityContext: () => {
            dispatch( actions.clearSecurityContext() );
        },
        setSecurityContextLoginMessage: (login_message, login_status) => {
            dispatch( actions.setSecurityContextLoginMessage(login_message, login_status) );
        }
    }

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
